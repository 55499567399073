import request from '@/utils/request'

//实习计划自主报名详情
export function getinternshipregistrationinfo(params) {
    return request({
        url: '/portal/practice-plan-freely-apply-info/get-info',
        method: 'GET',
        params
    })
}

//添加/编辑实习计划自主报名
export function addinternshipregistration(data) {
    return request({
        url: '/portal/practice-plan-freely-apply-info/add',
        method: 'post',
        data
    })
}

// 邀请函 承诺书模板
export function TemplateFile(params){
    return request({
        url: '/portal/practice-plan-freely-apply-info/get-apply-info',
        method: 'GET',
        params
    })
}
 